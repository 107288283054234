// src/services/admins.ts
import { Entity, EntityStatistics } from "../types/entity";
import { Pagination, DEFAULT_PAGE, DEFAULT_LIMIT } from "../types/pagination";
import http from "./http/index";

const EntityAPI = {
    getAllAdmins: async () => {
        return http.get<any>("/admin/entity/admin-users/").then((res) => res.data);
    },
    getAllEntitiesAssessments: async (payload: any) => {
        return http.get<any>(`/admin/entity/all-entities-assessments?page=${payload.page}&limit=${payload.limit}`).then((res) => res.data);
    }, 
    addUserToAdmin: async (userId: string) => {
        return http.post(`/admin/entity/admin-users/`, { userId }).then((res) => res.data);
    },
    removeUserFromAdmin: async (userId: string) => {
        return http.delete(`/admin/entity/admin-users/${userId}`);
    },
    getAllEntities: async (pagination?: Pagination) => {
        return http.get<any>("/admin/entity/", {
            params: {
                page: pagination?.page || DEFAULT_PAGE,
                limit: pagination?.limit || DEFAULT_LIMIT,
                sort: pagination?.sort || 'lastActivity',
                direction: pagination?.direction || 'desc'
            }
        }).then((res) => res.data);
    },
    getEntityDetails: async (entityId: string) => {
        try {
            const response = await http.get(`/admin/entity/${entityId}`);
            // Ensure tags property exists with defaults if not set
            return {
                ...response.data,
                tags: response.data.tags || ['Employee', 'Candidate', 'Other']
            };
        } catch (error) {
            throw error;
        }
    },
    //advisors
    getAllAdvisors: async () => {
        return http.get<any>("/admin/entity/advisors").then((res) => res.data); 
    },
    getEntityByToken: async (token: string) => {
        return http.get<any>(`/api/entity-links/${token}`).then((res) => res.data);
    },
    updateEntityDetails: async (entityId: string, entity: Partial<Entity>) => {
        return http.patch(`/admin/entity/${entityId}`, entity)
            .then((res) => res.data);
    },
    unarchiveEntity: async (entityId: string, status: string) => {
        return http.patch(`/admin/entity/${entityId}/archive`,{status}).then((res) => res.data);
    },
    switchToEntity: async (entityId: string) => {
        return http.get(`/admin/entity/switch-to-entity/${entityId}`, ).then((res) => res.data)
    },
    createNewEntity: async (entity: Partial<Entity>) => {
        return http.post("/admin/entity/", entity).then((res) => res.data);
    },
    getAllAdminUserEmails: async () => {
        return http.get<any>("/admin/entity/fetch-admin-user-emails").then((res) => res.data);
    },
    getEntityStatistics: async (entityId?: string): Promise<EntityStatistics[]> => {
        const url = `/admin/entity/statistics${entityId ? `/${entityId}` : ''}`
        return http.get<EntityStatistics[]>(url).then((res) => res.data);
    },
    updateEntitySettings: async (entityId: string, settings: {
        userAccessibility: boolean;
        tags: string[];
    }) => {
        return http.patch(`/admin/entity/${entityId}/settings`, settings)
            .then((res) => res.data);
    },
};

export default EntityAPI;