// import axios from "axios";
// import { API_BASE_URL } from "../../constants/main";


// const baseURL = API_BASE_URL || "";

// const userToken = JSON.parse(localStorage.getItem("user") || "{}")?.token;

// const axiosInstance = axios.create({
//   baseURL,
//   headers: {
//     common: {
//       Authorization: `Bearer ${userToken}`,
//     },
//   },
// });


// function setAuthToken(token = "") {
//   axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
// }

// const methods = {
//   get: axiosInstance.get,
//   post: axiosInstance.post,
//   put: axiosInstance.put,
//   delete: axiosInstance.delete,
//   patch: axiosInstance.patch,
//   setAuthToken,
// };

// export default methods;


import axios from "axios";
import { API_BASE_URL } from "../../constants/main";
import { ROLE } from "../../utils/common";

const baseURL = API_BASE_URL || "";

const getUserDetails = () => JSON.parse(localStorage.getItem("user") || "{}");

const axiosInstance = axios.create({
  baseURL,
  headers: {
    common: {
      Authorization: `Bearer ${getUserDetails()?.token || ""}`,
    },
  },
});

function hasPermission(url: any, method: any) {
  const userDetails = getUserDetails();
  const role = userDetails?.user?.role;
  const isStandardUser = role === ROLE.STANDARD_USER;

  if (isStandardUser) {
    if (url.includes('/admin/target/targets')) return false;
    if (url.includes('/admin/groups')) return false;
    if (url.includes('/admin/entity')) return false;
    if (url.includes('/admin/entity-user-tags')) return false;
    if (url.includes('/admin/assessments/assessments')) return false;
  }

  return true;
}

axiosInstance.interceptors.request.use(
  (config) => {
    const { url, method } = config;
    if (!hasPermission(url, method)) {
      return Promise.reject({ message: "Permission Denied", status: 403 });
    }
    return config;
  },
  (error) => Promise.reject(error)
);

function setAuthToken(token = "") {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
}

const methods = {
  get: axiosInstance.get,
  post: axiosInstance.post,
  put: axiosInstance.put,
  delete: axiosInstance.delete,
  patch: axiosInstance.patch,
  setAuthToken,
};

export default methods;
